/**
 * Galleries
 */

import $ from "jquery";

window.jQuery = $;
require("@fancyapps/fancybox/dist/jquery.fancybox");

const mashbo = {

    init() {

        const $galleryImage = $(".gallery__item-link");
        const $galleryButton = $("#GalleryButton");

        $galleryImage.fancybox({
            loop: true
        });
        $galleryButton.on("click", () => {
            $galleryImage.first().trigger("click");
        });

    }
};

mashbo.init();
